export const ru = {
    translation: {
        "Welcome to React": "aaa Welcome to React and react-i18next",
        email: "Электронная почта",
        submit: "Отправить",
        newVersionPopupFirst: "Портал AUTO KADA B2B был обновлен!",
        newVersionPopupSecond: "Нажмите 'ОБНОВИТЬ', чтобы применить последние изменения — затем вам потребуется снова войти в портал!",
        newVersionPopupSecondLoginpage: "Нажмите 'ОБНОВИТЬ', чтобы применить последние изменения!",
        updateB2BPortal: "Обновить",
        postponeB2BUpdate: "Не сейчас",
        login: {
            title: "Логин",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Имя пользователя",
            password: "Пароль",
            login: "Логин",
            forgotPassword: "Забыли пароль?",
            companyRegistration: "Зарегистрироваться",
            tnc: "Terms & conditions",
            privacyPolicy: "политика конфиденциальности",
            rights: "® 1994 - 2021, Auto Kada. все права защищены",
            errorMessageCredentials:
                "Пожалуйста, введите правильные имя пользователя и пароль. Оба поля могут быть чувствительны к регистру.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "Нажимая кнопку ниже, чтобы зарегистрироваться, вы соглашаетесь с нашей",
            privacyPolicyAgreement:
                "Я согласен (-а) с политикой конфиденциальности.",
        },
        privacyPolicy: {
            title: "ЗАЩИТА ЛИЧНЫХ ДАННЫХ",
            bulletPoints: {
                1: "В рамках настоящего Договора Стороны вправе осуществлять обработку личных данных согласно Регламенту Европейского парламента и Совета № 2016/679 от 27 апреля 2016 года о защите физических лиц при обработке личных данных и свободном обороте таких данных.",
                2: "Стороны удостоверяют и обязуются не передавать информацию (данные), полученную ими друг от друга (в том числе информацию и данные с настоящей домашней страницы, из системы В2В), третьим лицам без согласия другой Стороны.",
                3: "Стороны осуществляют соответствующие технические и организационные мероприятия для обеспечения по умолчанию обработки только личных данных, необходимых для деятельности Сторон в рамках настоящего Договора и каждой конкретной цели обработки. Вышеуказанная обязанность относится к объему собранных и переданных личных данных, степени их обработки, длительности хранения и доступности.",
                4: "Стороны обеспечивают, постоянно пересматривают и совершенствуют мероприятия для защиты личных данных от несанкционированного доступа, случайной потери, разглашения или уничтожения. Одна Сторона не несет ответственности за любой несанкционированный доступ к личным данным и (или) потерю личных данных, не зависящие от данной Стороны, например, обусловленные виной и (или) халатностью другой Стороны или третьего лица.",
                5: "В случае нарушения защиты личных данных Сторона обязана немедленно сообщить об этом другой Стороне и без необоснованного промедления сообщить об этом компетентному органу по надзору за нарушениями защиты личных данных (Государственной инспекции данных – www.dvi.gov.lv) не позднее 72 часов с момента, когда Стороне стало известно о нарушении, за исключением случаев, когда возможность того, что нарушение защиты личных данных может угрожать правам и свободам физических лиц, маловероятна.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Забыли пароль? Введите ваш адрес электронной почты, и мы вышлем вам инструкции для установки нового пароля.",
                errorMsg: "Введите корректный адрес электронной почты.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "Если существует учётная запись с указанным адресом электронной почты, то мы вышлем на него инструкции по установке пароля. В скором времени вы должны их получить.",
                text2:
                    "Если вы не получили письмо, убедитесь, что вы ввели адрес, который вы указывали при регистрации. Проверьте папку «спам».",
            },
            resetPasswordStep: {
                text:
                    "Введите, пожалуйста, пароль дважды, чтобы мы проверили правильность ввода.",
                newPassword: "Новый пароль",
                confirmPassword: "Подтвердить пароль",
                submit: "Изменить пароль",
                errorMsg: "Пароли, введенные в два поля, не совпадают.",
            },
            invalidResetLinkStep: {
                text:
                    "Возможно, ссылка на сброс пароля была недействительной, потому что ей уже пользовались. Запросите, пожалуйста, повторный сброс пароля.",
            },
            resetPasswordSuccessStep: {
                text:
                    "Ваш пароль установлен. Вы можете продолжать и войти на сайт.",
                login: "Авторизоваться",
            },
        },
        breadcrumbs: {
            backTitle: "Назад",
        },
        register: {
            title: "ПОСТАНОВКА НА УЧЕТ",
            company: {
                subtitle: "КОМПАНИЯ",
                name: "Название компании",
                registrationNumber: "Регистрационный номер",
                vatRegistrationNr: "НДС регистрационный номер",
                address: "Адрес",
                city: "Город",
                postalCode: "Почтовый индекс",
                country: "Страна",
                chooseCountry: "Выберите вашу страну",
                email: "Электронное письмо",
                phoneNumber: "Телефонный номер",
            },
            user: {
                subtitle: "ПОЛЬЗОВАТЕЛЬ",
                usernameInputExplanation:
                    "Требуется не более 150 символов. Буквы, цифры и @. Только + - _.",
                password: "Пароль",
                passwordConfirmation: "Подтверждение пароля",
                firstName: "Имя",
                lastName: "Фамилия",
                email: "Электронное письмо",
            },
            register: "РЕГИСТР",
            validationMessages: {
                name: "Поле имени должно содержать от 2 до 30 символов. Он не должен содержать специальных символов.",
                surname: "Поле фамилии должно содержать от 2 до 30 символов. Он не должен содержать специальных символов.",
                required: "Это поле обязательно к заполнению",
                userName: "Введите действительное имя пользователя",
                phoneNumber: "Номер телефона должен состоять из 11 цифр с 0 в начале.",
                password: "Пароль должен содержать от 6-20 символов и включать хотя бы одну букву и одну цифру",
                confirmPassword: "Пароли не соответствуют",
                tos: "Прочтите и подтвердите пользовательское соглашение.",
                email: "Пожалуйста, введите действительный адрес электронной почты.",
            },
            registrationSuccess: "Регистрация завершена. В скором времени с вами свяжется менеджер.",
        },
        headerUserMenu: {
            impersonateCustomer: "выдавать себя за клиента",
            stopImpersonation: "Выйти из режима «инкогнито»",
            switchLanguage: "Выбрать язык",
            switchCountry: "сменить страну",
            orderHistory: "История заказов",
            priceOffersHistory: "История предложений о цене",
            itemReturns: "Возврат товара / гарантия",
            logout: "Выйти",
        },
        header: {
            searchByCode: "OE/OEM/№ производителя",
            searchByVehicle: "VIN номер",
            searchByPlate: "Номерной знак",
            byCode: "По коду",
            byVehicle: "По автомобилю",
            byManufacturer: "По производителю",
            byPlate: "По номерному знаку",
            searchHistory: "ИСТОРИЯ ПОИСКА",
            setAsDefaultCart: "Установить как корзину по умолчанию",
            removeDefaultCart: "Удалить корзину по умолчанию",
            emptyCart: "Очистить корзину",
            deleteCart: "Удалить корзину",
            confirm: "Подтверждать",
            cancel: "Отмена",
            emptyCartMessage: "Очистка корзины удалит все ее элементы! Вы хотите продолжить?",
            deleteCartMessage: "Корзина будет удалена, а все ее элементы удалены! Вы хотите продолжить?",
            carByParameters: {
                title: "Идентификация автомобиля по параметрам",
                listVehicles: "Список транспортных средств",
                modifications: "Показать больше модификаций",
            },
            byVinFrame: {
                title: "По VIN/раме",
                tip: "Введите Vin или номер фрейма",
                errorVehicleSearch: "Не удалось найти автомобиль с указанными параметрами",
            },
            tags: {
                addTag: "Пожалуйста, выберите существующую тег или создайте новую",
                inputTag: "Введите тег",
                createTag: "Создать и добавить тег",
            },
            comments: {
                addComment: "Пожалуйста, добавьте комментарий",
                inputComment: "Введите комментарий",
                createComment: "Создать и добавить комментарий"
            }
        },
        sideMenu: {
            browseProductCatalog: "каталога продукции",
            newProducts: "Новые продукты",
            onlyItemsOnStock: "Показать только в наличии",
            chooseAManufacturer: "Выбрать поставщика",
        },
        notification: {
            actionName: {
                added: "добавлена в корзину",
                removed: "удалена из корзины",
                addedFav: "добавлена ​​в избранное",
                tooManyFav: "Достигнут лимит избранных (100)! Пожалуйста, удалите некоторые элементы, прежде чем добавить новые!",
                removedFav: "удалена из избранного",
                removedAvailNotification: "удален из списка уведомлений о доступности",
                addedAvailNotification: "добавлен в список уведомлений о доступности",
                cartEmptied: "Корзина пуста:",
                cartDeleted: "Корзина удалена:",
                cartDefaulted: "Сделать корзиной по умолчанию:",
                cartUndefaulted: "Удалена корзина по умолчанию:",
                addedTag: "добавлено в тег",
                tagDeleted: "Тег удален:",
                theTag: "Тег",
                removedFrom: "была удалена из:",
                commentSet: "Комментарий был установлен",
                commentRemoved: "Комментарий был удален",
                commentExists: "Комментарий уже существует"
            },
            itemHasBeen: "Позиция",
        },
        productsList: {
            fetching: "Загрузка дополнительных продуктов..",
            vehicle: "Автомобиль",
            productCatalog: "Каталог продукции",
            withDiscount: "Цены со скидкой",
            withVAT: "Цены с НДС",
            noInfoError: "В системе нет информации",
            availability: {
                green: "- Центральный склад",
                orange: "- Другие склады AK",
                yellow: "- Другие склады",
                grey: "- По запросу",
                transit: "- В пути",
            },
            order: "Заказ",
            sendPriceOffer: "Отправить ценовое предложение",
            successfulOrder: "Заказ принят.",
            successfulOffer: "Предложение по цене отправлено",
            orderHistory: {
                title: "История заказов",
                number: "Количество",
                created: "Создано",
                state: "Страна",
                user: "Пользователь",
                customer: "Клиент",
                total: "Итого",
                sourceLocation: "Исходное местоположение",
                vendorCode: "Код поставщика",
                description: "Описание",
                unitPrice: "Цена за единицу",
                count: "Подсчет",
                return: "Вернуться назад",
                delivery: "Доставка",
                items: "Позиции",
                offerReceiver: "Получатель предложения",
            },
            tableHeader: {
                image: "Изображение",
                product: "Продукт",
                AKCode: "Код AK",
                oem: "Код OEM",
                vendor: "Поставщик",
                stock: "Ассортимент товаров",
                availability: "Доступность",
                price: "Цена",
                quantity: "Добавить в корзину",
                comment: "Комментарий",
                tags: "Теги продукта",
            },
            tableVehiclesHeader: {
                description: "Описание",
                model: "Год модели",
                build: "Шасси",
                tonnage: "Тоннаж",
                axle: "Конфигурация оси",
            },
            tablePurchaseHistoryHeader: {
                date: "Дата покупки",
                count: "Количество",
                unitPrice: "Цена за единицу",
                tags: "Теги",
                user: "Пользователь",
            },
            stockFlyout: {
                title: "НАЛИЧИЕ НА СКЛАДЕ",
                deliveryInfo: "Возможна\nЭКСПРЕСС 24h + EUR",
                pleaseAsk: "ПОЖАЛУЙСТА, СПРОСИТЕ:",
                deliveryDate: "дату доставки"
            },
            favouriteFlyout: {
                add: "Добавить в избранное",
                remove: "Удалить из избранного",
            },
            notificationFlyout: {
                add: "Добавить в список уведомлений о доступности",
                remove: "Удалить из списка уведомлений о доступности",
            },
            commentFlyout: {
                add: "Добавить комментарий",
            },
            tagFlyout: {
                add: "Добавить теги",
            },
            publicFlyout: {
                text: "Наличие, заказ, оригинальные коды, поиск по VIN №. доступно только для зарегистрированных пользователей.",
                link: "Зарегистрируйтесь здесь",
            },
            cartButton: {
                title: "ДОБАВИТЬ В КОРЗИНУ",
                flyout: {
                    title:
                        "Пожалуйста, выберите существующую корзину или создайте новую",
                    cartName: "Название корзины",
                    btnCaption: "СОЗДАТЬ И ДОБАВИТЬ ПУНКТ",
                    inputPlaceholder: "Введите название корзины",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Нет необходимости заказывать эти товары, потому что у них есть автоматическая доставка",
                quantity: "Подсчет: ",
                totalWithoutVAT: "Итого, без НДС:",
                VAT: "НДС",
                total: "Итого"
            },
            sourceLocation: "Исходное местоположение",
            deliveryType: {
                title: "Тип доставки",
                deliveryAddress: {
                    title: "Адрес доставки",
                    name: "Имя",
                    address: "Адрес",
                    city: "Город",
                    postalCode: "Почтовый индекс",
                    email: "Электронная почта",
                    phone: "Телефон",
                    new: "Новый",
                },
                pickup: "Пункт выдачи заказов",
            },
            hasRelatedCodes: "У этого продукта есть связанные коды",
            commentsTitle: "Комментарии",
            priceOfferTitle: "Получатель ценового предложения",
            user: "Пользователь",
            date: "Дата",
            detailsModal: {
                tabs: {
                    relatedCodes: "СВЯЗАННЫЕ КОДЫ",
                    alternativeProducts: "АЛЬТЕРНАТИВНЫЕ ПРОДУКТЫ",
                    specification: "ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ",
                    oemCodes: "OEM КОДЫ",
                    linkedVehicles: "СВЯЗАННЫЕ ТРАНСПОРТНЫЕ СРЕДСТВА",
                    purchaseHistory: "ИСТОРИЯ ПОКУПОК",
                },
            },
        },
        appTour: {
            searchByCode: "B2B ищет как по кодам коллекции AUTO KADA, так и по номерам оригинальных производителей",
            logoButton: "Чтобы вернуться к первому изображению, можно использовать кнопку логотипа AUTO KADA или указатель «Назад»",
            grossPrices: "Показывать брутто-цены магазина или показывать цены клиента",
            vatPrices: "Показывать цены с НДС или без НДС",
            productCatalog: "Каталог продуктов – система быстрого поиска по группам продуктов",
            // searchByVin: "Меню поиска по VIN (номер шасси).",
            languageMenu: "Меню языков, истории поиска, счета и возврата/гарантии товара",
            salespersonLanguageMenu: "Меню: язык, история заказов, история предложений по цене, счета, возврат товара/гарантия",
            returnForm: "Если вам нужно вернуть товар, пожалуйста, заполните форму 'Возврат товаров'!",
            returnApplicationsList: "Здесь вы можете получить доступ ко всем своим поданным заявлениям на возврат товара и проверить их статусы и ответы.",
            shoppingCart: "Корзина заказов",
            tagItem: "Добавьте тег (например, чтобы указать, что продукт предназначен для конкретного автомобиля).\nТеги, добавленные к продукту, будут видны в истории покупок товара. Вы можете удалить конкретный тег для товара здесь или, открыв товар, удалить его. Все созданные теги и товары, связанные с ними, можно просмотреть, нажав на значок автомобиля в верхней части страницы, где также можно удалить созданные теги (удаление тега приведет к удалению всех с ним связанных товаров).",
            commentItem: "Добавьте комментарий (заметку) к продукту. Комментарий будет виден только вам. Вы можете просмотреть или удалить комментарий, открыв продукт, к которому он был добавлен.",
            itemsInStock: "Показывать только товары, которые есть на складе",
            selectedManufacturer: "Выбранный Производитель",
            notificationsAndFavorites: "Добавьте товар в избранное, нажав на значок шестеренки.\nДобавьте товар в список уведомлений, нажав на значок колокольчика (отображается рядом с значком шестеренки, если возможно), чтобы получить уведомление, когда он снова появится на складе.\nСписки добавленных вами товаров доступны вверху страницы рядом со значком корзины. Если какой-либо из продуктов, добавленных в список уведомлений, станет доступным, вы увидите количество доступных продуктов, отображаемое в зеленом кружке рядом со значком списка уведомлений.",
            favorites: "Добавьте товар в избранное, нажав на значок шестеренки.\nСписок любимых товаров доступен вверху страницы рядом со значком корзины.",
            // manufacturersMenu: "Меню Производителя",
            searchParameters: "Выбранные параметры поиска ‒ поиск ведется только по этим параметрам также и в случае поиска по коду AK или OE",
            particularProduct: "При нажатии на конкретный продукт откроется дополнительное окно, где указаны связанные альтернативные продукты конкретно для этого номера. Также будут показаны техническая информация, фотографии, оригинальные номера и модели, для которых предназначен этот продукт, а также история покупок продукта.",
            searchDone: "Поиск осуществляется после введения полного VIN (номера шасси)",
            openDetails: "При нажатии на искомую запасную часть открываются сведения о цене, а также об имеющемся на складе AUTO KADA количестве и доступных производителях",
            pictureNumbers: "Также можно вести поиск, нажимая на номера, находящиеся рядом с интересующей деталью",
            addToCart: "Положить товар в корзину заказов прямо из каталога VIN.\n P.S. Для удобства можно открыть в браузере несколько окон, чтобы искать по нескольким номерам VIN или одновременно ‒ другие номера AK или OE.",
            chooseBasket: "Выберите корзину или создайте новую",
            basketContent: "Содержимое корзины, количество – с возможностью редактирования, цены, суммы",
            deliveryMethod: "Вид доставки. В первый раз при выборе доставки AUTO KADA, почты нужно будет указать адрес, при последующих заказах адрес нужно будет указывать только в том случае, если Вы захотите изменить место доставки товара.",
            orderComments: "Примечания для более точного выполнения заказа",
            priceOfferEmail: "Если вы хотите отправить ценовое предложение клиенту, введите здесь электронную почту клиента, а затем нажмите кнопку 'ОТПРАВИТЬ ЦЕНОВОЕ ПРЕДЛОЖЕНИЕ'",
            replyTour: "Повторить инструкцию",
            next: "Следующее",
            finish: "Финиш",
        },
        invoices: {
            availableCreditLimit: "Доступный кредитный лимит",
            totalCreditLimit: "Полный кредитный лимит",
            openOrders: "Открытые заказы",
            openInvoices: "Открытые счета",
            totalBalance: "Общий баланс",
            balance: "Остаток средств",
            latePayments: "Просроченные платежи",
            invoices: "Счета",
            invoiceDate: "Дата счета",
            invoiceNumber: "Номер счета",
            totalValue: "Общая стоимость",
            remainingValue: "Оставшееся значение",
            dueDate: "Срок сдачи",
            status: "Положение дел",
            documentNo: "Документ №",
            filter: "Фильтр",
            discardFilters: "Отменить фильтры",
            from: "С",
            to: "до",
            paid: "Оплаченный",
            unpaid: "Неоплачиваемый",
            partiallyPaid: "Частично оплачено",
            unpaidLate: "Неоплачиваемый (поздно)",
            partiallyPaidLate: "Частично оплачено (поздно)",
            clear: "Очистить",
            cancel: "Отмена",
            ok: "Хорошо"
        },
        autocomplete: {
            noOptions: "Нет выбора",
            open: "Открыть",
            close: "Закрывать"
        },
        dataGrid: {
            noResults: "Нет результатов"
        },
        itemReturnForm: {
            title: "Возврат товара",
            serverError: "Форма возврата товара недоступна (ошибка сервера), попробуйте позже!",
            submitError: "Ошибка сервера, попробуйте позже!",
            submit: "Отправить",
            formFields: {
                customer: "Клиент",
                chooseCustomer: "Выберите клиента",
                searchCustomer: "Поиск клиента",
                partNumber: "Артикул",
                enterPartNumber: "Введите действительный AK код",
                validAKCode: "AK код действителен",
                docNumber: "Номер счета/чека",
                enterDocNumber: "Введите номер документа",
                enterCount: "Сколько деталей будет возвращено",
                purchaseDate: "Дата покупки",
                legalAddr: "Юридический адрес",
                enterLegalAddr: "Введите юридический адрес",
                itemInstalledDate: "Дата установки",
                itemRemovedDate: "Дата удаления",
                itemInstalledOdo: "Показания одометра при установке (км)",
                enterOdo: "Введите показания одометра (км)",
                itemRemovedOdo: "Показания одометра при удалении (км)",
                vehicleIdentity: "Автомобиль: номер шасси/прицеп: производитель, номер моста",
                enterIdentity: "Введите информацию об идентификации транспортного средства",
                phone: "Телефон",
                enterPhone: "Телефон для вопросов/уведомлений",
                email: "Дополнительный электронный адрес",
                returnIfRejected: "Вы хотите получить деталь(и) обратно, если рекламация отклонена?",
                yes: "Да",
                no: "Нет",
                files: "Файлы",
                enterEmail: "Дополнительный электронный адрес для вопросов/уведомлений",
                description: "Описание дефекта",
                provideDescription: "Опишите дефект",
                shopReturned: "Магазин, куда товар был/будет возвращен для проверки",
                chooseShop: "Выберите магазин",
                chooseFiles: "Выберите файлы",
                remove: "Удалить",
                totalSize: "Общий размер",
            },
            validationMessages: {
                removalDateComparison: "Дата удаления должна быть больше или равна дате установки",
                installDateComparison: "Дата установки не может быть больше даты удаления",
                removedOdoComparison: "Показания одометра при удалении должны быть больше или равны показаниям одометра при установке",
                installedOdoComparison: "Показания одометра при установке не могут быть больше показаний одометра при удалении",
                required: "Это обязательное поле",
                wholeNumbers: "Введите целое число, пожалуйста",
                validAKCodeNumbers: "Код AK должен содержать только цифры",
                invalidAKCode: "Неверный код AK",
                akCodeValidationErr: "Ошибка валидации кода AK. Пожалуйста, попробуйте позже!",
                email: "Введите действительный адрес электронной почты, пожалуйста",
                maxDate: "Указанная дата в будущем",
                minDate: "Пожалуйста, введите дату после 2000.01.01",
                invalidDateFormat: "Недопустимый формат даты",
                totalFileSize: "Общий размер файлов не может превышать 20 МБ",
            },
        },
        itemReturnList: {
            title: "Список заявок на возврат товара",
            serverError: "Список возвращенных товаров недоступен (ошибка сервера), попробуйте позже!",
            tableTitles: {
                itemCardTitle: "Номер возвращенного товара",
                returnApplication: "Вернуть заявку",
                formSubmitted: "Форма отправлена",
                itemNumber: "Артикул",
                user: "Пользователь",
                shopReturned: "Магазин",
                status: "Статус",
            },
            statuses: {
                received: "Получено",
                inReview: "На рассмотрении",
                atTheManufacturer: "Отправлено производителю",
                accepted: "Принято",
                rejected: "Отклонено",
            },
        },
        itemReturnInstructions: {
            mainPoints: {
                first: "ВОЗВРАТ ТОВАРА ДОЛЖЕН БЫТЬ ОСУЩЕСТВЛЕН В ТЕЧЕНИЕ 10 ДНЕЙ С МОМЕНТА ПОКУПКИ",
                second: "ТОВАР ДОЛЖЕН БЫТЬ В НЕПОВРЕЖДЕННОЙ И НЕОБОДРАННОЙ ОРИГИНАЛЬНОЙ УПАКОВКЕ",
                third: "ДЕТАЛИ, ПОДАВАЕМЫЕ НА РАССМОТРЕТИЕ В РАМКАХ ГАРАНТИЙНОГО РАССМОТРЕНИЯ, ДОЛЖНЫ БЫТЬ ОЧИЩЕНЫ С СОХРАНЕНИЕМ ЗНАКОВ ПРОИЗВОДИТЕЛЯ",
                fourth: "ДЛЯ УДОБСТВА НАШИХ КЛИЕНТОВ ПРОСИМ ЗАПОЛНИТЬ ФОРМУ ВОЗВРАТА ЭЛЕКТРОННО"
            },
            faq: {
                description: {
                    question: "Что рекомендуется указывать в разделе \"Описание дефекта\"?",
                    answer: "Проблему в работе детали следует описать:\n1. Как она возникла и когда, какие действия были предприняты и т.д.;\n2. На детали должна быть указана (помечена) область проблемы;\n3. Желательно прикрепить видео, подтверждающее конкретный дефект;\n4. Видеозапись обязательна в случае утечки масла и других жидкостей, утечки воздуха;\n5. Детали электрических схем - должны иметь компьютерный вывод ошибок или результаты измерений от электрика (может быть в видеоформате);\n6. Оригинальные детали: педали газа, модуляторы (OE, Knor-Bremse, WABCO); Электрические регулировочные клапаны (OE, Knor-Bremse, WABCO); блоки управления (OE, Knor-Bremse, WABCO); датчики управления (OE, Knor-Bremse, WABCO); выписка из кодов ошибок должна быть предоставлена вместе с заявленной дефектной деталью."
                },
                  cases: {
                    question: "В каких случаях товар не будет принят к возврату?",
                    answer: "1. Если оригинальная упаковка повреждена или загрязнена или не сохранена;\n2. Электрические детали не принимаются при повреждении запечатанной упаковки;\n3. Ремонтные комплекты, если их упаковка открыта;\n4. Специально заказанные товары не подлежат обмену или возврату."
                },
                  processLength: {
                    question: "Как долго занимает обработка гарантийного случая?",
                    answer: "Вероятные случаи гарантии рассматриваются и направляются производителю представителем службы AUTO KADA в течение 1 недели.\nПроизводитель несет ответственность за оставшуюся часть гарантии, включая ее срок."
                },
                  conditions: {
                    question: "При каких условиях принимаются старые случаи?",
                    answer: "Старые случаи должны быть чистыми и без масла.\nВозврат сцепления SACHS общие <link1>условия</link1> и <link2>сводка</link2>.\nПолитика возврата турбин BTS TURBO см. <link3>здесь</link3>.\nПравила возврата случаев EMITEC ADBLUE можно найти <link4>здесь</link4>.\nОзнакомьтесь с политикой возврата случаев ALBONAIR ADBLUE <link5>здесь</link5>.\nПравила возврата случаев ECOFIT ADBLUE можно найти <link6>здесь</link6>.\nОзнакомьтесь с правилами возврата тормозных суппортов <link7>здесь</link7>.\nПолитика возврата WABCO см. <link8>здесь</link8>.\nПолитика возврата KNORR-BREMSE см. <link9>здесь</link9>.\nПолитика возврата REMA.GERMANY см. <link10>здесь</link10>."
                },
            },
            itemDescription: {
                leakage: "в случае утечки воздуха, газа или жидкости место утечки должно быть отмечено на детали;",
                electronics: "для любой электрической детали, если дефект не виден визуально и для проверки детали требуется использование диагностического или измерительного оборудования, должны быть предоставлены приложения (распечатки кодов ошибок, ленты/карты тахографов, актуальные значения проведенных измерений);",
                rejectedReclamation: "Если выбран вариант 'Да' и деталь отправляется на завод для проверки, может взиматься плата за её возврат.",
            },
        },
        itemReturnItem: {
            part: "Товар",
            status: "Статус",
            submitDate: "Форма отправлена",
            lastUpdated: "Последнее обновление",
            docNumber: "Номер документа",
            count: "Количество",
            additionalEmail: "Дополнительный электронный адрес",
            installDate: "Установлено (дата)",
            removeDate: "Удалено (дата)",
            installOdo: "Установлено (одометр)",
            removeOdo: "Удалено (одометр)",
            phone: "Телефон",
            purchaseDate: "Дата покупки",
            registeredOffice: "Юридический адрес",
            shopReturned: "Магазин",
            user: "Пользователь",
            vehicle: "Идентификация транспортного средства",
            userInfo: "Информация о пользователе",
            returnRejected: "Вернуть товар, если отклонено",
            description: "Описание дефекта",
            attachments: "Вложения",
            response: "Ответ",
            error: "Ошибка сервера. Попробуйте снова позже!"
        },
    },
};

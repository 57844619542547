import styled, { css } from "styled-components";
import { breakpoints } from "../../../../core/utils/style";
import { theme } from "../../../../core/styles/theme";
import { CustomScrollArea } from "../../../Header/Carts/style";
import Typography from "../../../Typography";
import Help from "@material-ui/icons/Help";


export const StockAnchor = styled(Typography).attrs(
    ({ isphone, stockiszero }) => ({
        color: !stockiszero ? "#F28A22" : "#37474f",
        customVariant: isphone ? "captionSlim" : "subheadingSlim",
        textAlign: "center",
    })
)`
    cursor: pointer;
    font-weight: normal;
    font-size: 22px;
    line-height: 16px;
    letter-spacing: 0.15px;
`;

export const StockFlyoutWrapper = styled.div`
    background: white;
    max-height: 800px;
    width: 500px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25),
        0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
 
    @media (max-width: ${breakpoints.sm}) {
        max-height: 700px;
        width: 100%;
    }
    @media (min-width: ${breakpoints.md}) {
        ${({ length, detailedStock }) =>
        length <= 8 ?
            (detailedStock ?
                css`
                    width: 285px;
                `
                :
                css`
                    width: 250px;
                `
            )
        : length > 8 && 
            css`
                width: 600px;
            `
        }
    }
`;

export const StockList = styled(CustomScrollArea)`
    border-bottom: 1px solid #37474f;
    border-top: 1px solid #37474f;
    padding-bottom: 5px;
    padding-top: 5px;
    max-height: 80vh;
    overflow: auto;
    
    @media (min-width: ${breakpoints.md}) {
        ${({ length }) =>
            length <= 8 ? (
                css`
                    max-height: 190px;
                `
            ) : (length > 8 && length <= 11) ? (
                css`
                    max-height: 150px;
                `
            ) : (length > 11 && length <= 14) ? (
                css`
                    max-height: 190px;
                `
            ) : (length > 14 && length <= 20) ? (
                css`
                    max-height: 250px;
                `
            ) : (length > 20 && length <= 30) ? (
                css`
                    max-height: 350px;
                `
            ) : (length > 30 && length <= 60) ? (
                css`
                    max-height: 460px;
                `
            ) : (
                css`
                    max-height: 570px;
                `
            )
        }
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        border: 0;
        padding: 0;
    }
`;

export const StockTitle = styled.h6`
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: ${({ help }) => (help ? "5px" : "12px")};
    
    svg {
        margin-left: auto;
        cursor: pointer;
    }

    @media (min-width: ${breakpoints.md}) {
        font-size: 16px;
        text-align: left;
        margin-bottom: ${({ help }) => (help ? "5px" : "18px")};
    }
`;

export const StockLine = styled.div`
    align-items: center;
    display: flex;
    padding-right: 18px;
    paddin-top: 2px;
    padding-bottom: 2px;
    flex: 0 0 100%;
    ${({ salespersonShop, stockItem }) =>
        salespersonShop &&
        salespersonShop.some(shopItem => stockItem.startsWith(shopItem)) &&
        css`
        border-bottom: 1px solid rgb(127 238 127);
    `}
`;

export const StockDot = styled.div` 
    height: 10px;
    width: 10px;
    background-color: rgb(127 238 127);
    border-radius: 50%;
    display: inline-block;
    
    ${({ type }) =>
        type === "flyout" &&
        css`
        margin-right: 10px;
    `}  
`;
export const StockKey = styled.div`
    color: #37474f;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
`;

export const StockValue = styled(StockKey)`
    font-weight: bolder;
    margin-left: auto;
    ${({ purchased }) =>
        purchased > 0 &&
        css`
            margin-left: 5px;
    `}
    
    ${({ value }) =>
        value === 0 &&
        css`
            color: #f28a22;
    `} 
    ${({ type }) =>
        type === "first" &&
        css`
            font-weight: normal;
            margin-right: auto;
            margin-left: 3px;
    `}
    ${({ type }) =>
        type === "third" &&
        css`
            margin-left: auto;
            color: #bcbcbc;
    `}
    ${({ blockedMinPick }) =>
        blockedMinPick === true &&
        css`
            color: #f28a22;
    `} 
`;

export const DeliveryInfo = styled.div`
    margin-top: 10px;
    font-size: 14px;

    ${({ help }) =>
        help === true &&
        css`
            margin-top: 0px;
        `
    }
`;

export const StyledHelp = styled(Help)`
    color: ${theme.colors.grey};
    font-size: 14px !important;
`;

export const LocationContactInfo = styled.div`
    color: ${theme.colors.grey};
    font-size: 14px;
`;

import { Switch, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import routes from "./core/routes";
import Notification from "./components/Notification";
import { theme } from "./core/styles/theme";
import { ThemeProvider } from "styled-components";
import { AuthRoute } from "./pages/Login/components/AuthRoute";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./core/redux/asyncActions/global";
import { getAuthState } from "./core/api/authHelper";
import withTourController from "./components/Tour";
import React from "react";
import ClearPage from "./pages/ClearPage";
import { NewVersionPopupContainer, ReloadAndLogoutButton, PostponeUpdateButton } from "./components/NewVersionPopup";
import { DEFAULT_PATH } from "./core/routes";

const fetchCurrentVersion = async () => {
	try {
		const response = await fetch("/version.json", { cache: "no-store" });
		const data = await response.json();
		return data.version;
	} catch (error) {
		console.error("Error fetching current version:", error);
		return null;
	}
};

function App() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const location = useLocation();
	const userAuthenticatedStatus = useSelector(
		(state) => state.global.userAuthenticated
	);
	const authState = getAuthState();
	const userAuthenticated = userAuthenticatedStatus && authState?.accessToken;
	const isUserDataFetched = useSelector((state) => !!state.global.userData);

	const [reloadRequired, setReloadRequired] = useState(false);
	const [dismissed, setDismissed] = useState(false);
	const { b2bVersion } = useSelector((state) => state.global);

	const isLoginPage = location.pathname === DEFAULT_PATH;

	useEffect(() => {
		const checkVersion = async () => {
			const fetchedVersion = await fetchCurrentVersion();
			if (fetchedVersion && fetchedVersion !== b2bVersion) {
				setReloadRequired(true);
			}
		};
		checkVersion();
		const interval = setInterval(() => {
			checkVersion();
			setDismissed(false);
		}, 60000); // Check every 1 minute
		return () => clearInterval(interval);
	}, [b2bVersion]);
	
	if (userAuthenticated && !isUserDataFetched) {
		// fetching mandatory data for the app
		dispatch(fetchUserData());
	}

	return (
		<div className="App">
			{reloadRequired && !dismissed && (
				<NewVersionPopupContainer>
					<div style={{ fontWeight: "500", fontSize: "18px", marginBottom: "10px" }}>{t("newVersionPopupFirst")}</div>
					{isLoginPage ? t("newVersionPopupSecondLoginpage") : t("newVersionPopupSecond")}
					<div style={{ display: "flex", flexDirection: "row", marginTop: "15px" }}>
						{!isLoginPage && (
							<PostponeUpdateButton onClick={() => setDismissed(true)}>
								{t("postponeB2BUpdate").toUpperCase()}
							</PostponeUpdateButton>
						)}
						<ReloadAndLogoutButton onClick={() => {localStorage.clear(); window.location.reload();}}>
							{t("updateB2BPortal").toUpperCase()}
						</ReloadAndLogoutButton>
					</div>
				</NewVersionPopupContainer>
			)}
			<ThemeProvider theme={theme}>
				<Switch>
					<Route key="/c" path="/c" component={ClearPage} />
					{routes.map((route) => {
						const { path, Component, type } = route;
						return (
							<AuthRoute
								key={path}
								path={path}
								component={Component}
								type={type}
							/>
						);
					})}
				</Switch>
				<Notification />
			</ThemeProvider>
		</div>
	);
}

export default withTourController(React.memo(App));

export const en = {
    translation: {
        "Browse Product Catalog": "BROWSE PRODUCT CATALOG",
        "Show only items on stock": "Show only items on stock",
        "Choose a manufacturer": "Choose a manufacturer",
        "Product Catalog": "Product Catalog",
        email: "Email",
        submit: "Submit",
        newVersionPopupFirst: "AUTO KADA B2B portal has been updated!",
        newVersionPopupSecond: "Click 'UPDATE' to apply the latest changes — after that, you will need to log in again!",
        newVersionPopupSecondLoginpage: "Click 'UPDATE' to apply the latest changes!",
        updateB2BPortal: "Update",
        postponeB2BUpdate: "Not now",
        login: {
            title: "Login",
            language: {
                en: "English",
                lv: "Latvian",
                lt: "Lithuanian",
                et: "Estonian",
                fi: "Finnish",
                no: "Norwegian",
                ru: "Russian",
                sv: "Sweeden",
            },
            clientId: "Username",
            password: "Password",
            login: "Login",
            forgotPassword: "Forgotten your password?",
            companyRegistration: "Register",
            tnc: "Terms & conditions",
            privacyPolicy: "Privacy policy",
            rights: "® 1994 - 2021, Auto Kada. All rights reserved",
            errorMessageCredentials:
                "Please enter a correct username and password. Note that both fields may be case-sensitive.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "By clicking below to signup, you’re agreeing to our",
            privacyPolicyAgreement: "I agree to privacy policy.",
        },
        privacyPolicy: {
            title: "PERSONAL DATA PROTECTION",
            bulletPoints: {
                1: "The Parties may perform personal data processing within the framework of this Agreement in accordance with Regulation of the European Parliament and of the Council (EU) 2016/679 of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data.",
                2: "The Parties confirm and shall undertake to ensure that the information (data) they acquire from the other party (including - information, data from this webpage, B2B system) shall not be delivered to third persons without the consent of the other party.",
                3: "The Parties shall implement appropriate technical and organisational measures in order to ensure that by default only such personal data, which are required for activities of the parties within the framework of this Agreement, are processed, and for each particular purpose of processing. The above mentioned obligation shall refer to the scope of collected and transferred personal data, degree of processing, period of storage and availability thereof.",
                4: "The Parties shall ensure, review on a regular basis and improve protection measures in order to protect personal data from unauthorised access, accidental loss, disclosure or destruction. One Party shall not be held liable for any unauthorised access to personal data and/or personal data loss if it is not dependent on the Party, for example due to the fault and/or negligence of the other Party or third person.",
                5: "In the case of a personal data protection breach the Party shall immediately notify the other Party of such, but shall not notify the competent supervisory authority (State Data Inspectorate - www.dvi.gov.lv), of the personal data protection breach without unjustified delay, not later than within 72 hours from the moment when the Party has become aware of the breach, except for in cases when it is unlikely that the personal data protection breach could cause a risk to the rights and freedoms of natural persons.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Forgotten your password? Enter your email address below, and we'll email instructions for setting a new one.",
                errorMsg: "Enter a valid email address.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "We've emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly.",
                text2:
                    "If you don't receive an email, please make sure you've entered the address you registered with, and check your spam folder.",
            },
            resetPasswordStep: {
                text:
                    "Please enter your new password twice so we can verify you typed it in correctly.",
                newPassword: "New password",
                confirmPassword: "Confirm password",
                submit: "Change my password",
                errorMsg: "The two password fields didn't match.",
            },
            invalidResetLinkStep: {
                text:
                    "The password reset link was invalid, possibly because it has already been used. Please request a new password reset.",
            },
            resetPasswordSuccessStep: {
                text:
                    "Your password has been set. You may go ahead and log in now.",
                login: "Log in",
            },
        },
        breadcrumbs: {
            backTitle: "Back",
        },
        register: {
            title: "REGISTRATION",
            company: {
                subtitle: "COMPANY",
                name: "Company name",
                registrationNumber: "Registration number",
                vatRegistrationNr: "VAT registration number",
                address: "Address",
                city: "City",
                postalCode: "Postal code",
                country: "Country",
                chooseCountry: "Choose your country",
                email: "Email",
                phoneNumber: "Phone number",
            },
            user: {
                subtitle: "USER",
                usernameInputExplanation:
                    "Required 150 character or fewer. Letters, digits and @ . + - _ only.",
                password: "Password",
                passwordConfirmation: "Password confirmation",
                firstName: "First name",
                lastName: "Last name",
                email: "Email",
            },
            register: "REGISTER",
            validationMessages: {
                name: "The name field must contain a minimum of 2 and a maximum of 30 characters. It should not contain special characters.",
                surname: "The surname field must contain a minimum of 2 and a maximum of 30 characters. It should not contain special characters.",
                required: "This field is required",
                userName: "Enter a valid username",
                phoneNumber: "The phone number should be 11 digits with a leading 0.",
                password: "Password must be 6-20 characters long and include at least one letter and one digit",
                confirmPassword: "Passwords do not match",
                tos: "Please read and confirm the user agreement.",
                email: "Please enter a valid e-mail address.",
            },
            registrationSuccess: "The registration has been completed. A manager will contact you shortly.",
        },
        headerUserMenu: {
            impersonateCustomer: "Impersonate customer",
            stopImpersonation: "Stop impersonation",
            switchLanguage: "Switch language",
            switchCountry: "Switch country",
            orderHistory: "ORDER HISTORY",
            priceOffersHistory: "Price offers history",
            itemReturns: "ITEM RETURNS / WARRANTY",
            logout: "LOGOUT",
        },
        header: {
            searchByCode: "OE/OEM/Vendor number",
            searchByVehicle: "VIN number",
            searchByPlate: "Plate number",
            byCode: "By code",
            byVehicle: "By vehicle",
            byManufacturer: "By manufacturer",
            byPlate: "By plate number",
            searchHistory: "SEARCH HISTORY",
            setAsDefaultCart: "Set as default cart",
            removeDefaultCart: "Remove default cart",
            emptyCart: "Empty the cart",
            deleteCart: "Delete cart",
            confirm: "Confirm",
            cancel: "Cancel",
            emptyCartMessage: "Emptying the cart will remove all of its items! Do you want to proceed?",
            deleteCartMessage: "The cart will be deleted and all of its items removed! Do you want to proceed?",
            carByParameters: {
                title: "Identification of car by parameters",
                listVehicles: "List vehicles",
                modifications: "Show more modifications",
            },
            byVinFrame: {
                title: "By VIN/Frame",
                tip: "Enter Vin or frame number",
                errorVehicleSearch: "Failed to find vehicle with the provided parameters",
            },
            tags: {
                addTag: "Please choose existing tag or create new one",
                inputTag: "Enter a tag",
                createTag: "Create and add a tag",
            },
            comments: {
                addComment: "Please add a comment",
                inputComment: "Enter a comment",
                createComment: "Create and add a comment"
            }
        },
        sideMenu: {
            browseProductCatalog: "PRODUCT CATALOG",
            newProducts: "New products",
            onlyItemsOnStock: "Show only items on stock",
            chooseAManufacturer: "Choose a manufacturer",
        },
        notification: {
            actionName: {
                added: "added to cart",
                removed: "removed from cart",
                addedFav: "added to favourites",
                tooManyFav: "Favourites limit (100) is reached! Please remove some items before adding new ones!",
                removedFav: "removed from favourites",
                removedAvailNotification: "removed from availability notifications list",
                addedAvailNotification: "added to availability notifications list",
                cartEmptied: "The cart has been emptied:",
                cartDeleted: "The cart has been deleted:",
                cartDefaulted: "Set as default cart:",
                cartUndefaulted: "Removed default cart:",
                addedTag: "added to tag",
                tagDeleted: "The tag has been deleted:",
                theTag: "The tag",
                removedFrom: "has been removed from:",
                commentSet: "The comment has been set",
                commentRemoved: "The comment has been removed",
                commentExists: "Comment already exists"
            },
            itemHasBeen: "The item has been",
        },
        productsList: {
            fetching: "Loading in more products...",
            vehicle: "Vehicle",
            productCatalog: "Product catalog",
            withDiscount: "Prices with discount",
            withVAT: "Prices with VAT",
            noInfoError: "There is no information in the system",
            availability: {
                green: "- Central warehouse",
                orange: "- Other AK warehouses",
                yellow: "- Other warehouses",
                grey: "- On request",
                transit: "- In transit",
            },
            successfulOrder: "The order has been accepted.",
            successfulOffer: "The price offer has been sent",
            order: "Order",
            sendPriceOffer: "Send price offer",
            orderHistory: {
                title: "Order history",
                number: "Number",
                created: "Created",
                state: "State",
                user: "User",
                customer: "Customer",
                total: "Total",
                sourceLocation: "Source Location",
                vendorCode: "Vendor code",
                description: "Description",
                unitPrice: "Unit price",
                count: "Count",
                return: "Return Back",
                delivery: "Delivery",
                items: "Items",
                offerReceiver: "Offer receiver",
            },
            tableHeader: {
                image: "Image",
                product: "Product",
                AKCode: "AK code",
                oem: "OEM code",
                vendor: "Vendor",
                stock: "Stock",
                price: "Price",
                quantity: "Add to chart",
                availability: "Availability",
                comment: "Comment",
                tags: "Product tags",
            },
            tableVehiclesHeader: {
                description: "description",
                model: "model year",
                build: "build",
                tonnage: "tonnage",
                axle: "axle configuration",
            },
            tablePurchaseHistoryHeader: {
                date: "Purchase date",
                count: "Count",
                unitPrice: "Unit price",
                tags: "Tags",
                user: "User",
            },
            stockFlyout: {
                title: "STOCK AVAILABILITY",
                deliveryInfo: "Possible\nEXPRESS 24h + EUR",
                pleaseAsk: "PLEASE ASK:",
                deliveryDate: "delivery date",
            },
            favouriteFlyout: {
                add: "Add to favourites",
                remove: "Remove from favourites",
            },
            notificationFlyout: {
                add: "Add to availability notifiactions list",
                remove: "Remove from availability notifications list",
            },
            commentFlyout: {
                add: "Add a comment",
            },
            tagFlyout: {
                add: "Add tags",
            },
            publicFlyout: {
                text: "Availability, Ordering, OE Codes, Search by VIN No. available to registered users only.",
                link: "Sign up here",
            },
            cartButton: {
                title: "ADD TO CART",
                flyout: {
                    title: "Please choose existing cart or create new one",
                    cartName: "Cart Name",
                    btnCaption: "CREATE AND ADD ITEM",
                    inputPlaceholder: "Enter cart name",
                },
            },
            cartInfoSection: {
                automaticDelivery: "It is not necessary to order these items because they have automatic delivery",
                quantity: "Count: ",
                totalWithoutVAT: "Total without VAT:",
                VAT: "VAT",
                total: "Total",
            },
            sourceLocation: "Source location",
            deliveryType: {
                title: "Delivery type",
                deliveryAddress: {
                    title: "Delivery address",
                    name: "Name",
                    address: "Address",
                    city: "City",
                    postalCode: "Postal code",
                    email: "Email",
                    phone: "Phone",
                    new: "New",
                },
                pickup: "Pickup location",
            },
            hasRelatedCodes: "This product has related codes",
            commentsTitle: "Comments",
            priceOfferTitle: "Price offer receiver",
            user: "User",
            date: "Date",
            detailsModal: {
                tabs: {
                    relatedCodes: "RELATED CODES",
                    alternativeProducts: "ALTERNATIVE PRODUCTS",
                    specification: "SPECIFICATION",
                    oemCodes: "OEM CODES",
                    linkedVehicles: "LINKED VEHICLES",
                    purchaseHistory: "PURCHASE HISTORY",
                },
            },
        },
        appTour: {
            searchByCode: "B2B searches for both AUTO KADA stock codes and original manufacturer numbers",
            logoButton: "You can use AUTO KADA logo button or the \"Return\" link to get back to the first view",
            grossPrices: "Show store gross prices or show customer prices",
            vatPrices: "Show prices with or without VAT",
            productCatalog: "Product Catalogs - quick search by product groups",
            // searchByVin: "Search by VIN (Chassis Number) menu",
            languageMenu: "Language, order history, invoices and item return/warranty menu",
            salespersonLanguageMenu: "Menu: language, order history, price offer history, invoices, item return/warranty",
            returnForm: "If you need to return a product, please fill out the 'Return of goods' form!",
            returnApplicationsList: "Here you can access all your submitted product return applications and check their statuses and responses.",
            shoppingCart: "Shopping Cart",
            tagItem: "Add a tag (e.g., to mark that the product is intended for a specific car).\nThe tags added to the product will be visible in the purchase history of the item. You can remove a specific tag for a product here or by opening the product. All created tags and the products associated with them can be viewed by clicking on the car icon at the top of the page, where you can also delete created tags (deleting a tag will remove all products associated with it).",
            commentItem: "Add a comment (note) to the product. The comment will be visible only to you. You can view or remove the comment by opening the product to which it was added.",
            itemsInStock: "Show only items in stock",
            selectedManufacturer: "Selected Manufacturer",
            notificationsAndFavorites: "Add product to your favorites by clicking on the gear icon.\nAdd product to your notifications list by clicking on the bell icon (visible next to the gear icon if applicable), to be notified when it is back in stock.\nYour lists of added products are accessible at the top of the page next to the shopping cart icon. If any of the products added to the notification list become available, you will see a number of available products displayed in a green circle next to the notification list icon.",
            favorites: "Add product to your favorites by clicking on the gear icon.\nYour list of favorite products is accessible at the top of the page next to the shopping cart icon.",
            // manufacturersMenu: "Manufacturer's menu",
            searchParameters: "Selected search parameters are searched by these parameters only by AK or OE code",
            particularProduct: "Clicking on a particular product will open an additional window with specific alternatives related to this number products, technical information, pictures, original numbers and models for which the product is intended are shown, as well as product purchase history.",
            searchDone: "The search is done after entering the full VIN (Chassis No.)",
            openDetails: "When you open the searched spare part by clicking on it shows the manufacturers, quantities and prices available in the AUTO KADA warehouse",
            pictureNumbers: "You can also search by pressing the numbers next to the detail you are interested in",
            addToCart: "Direct the item from the VIN catalog to your order cart.\n P.S. To make it easier to work, you can open multiple windows in your web browser to search for multiple VIN numbers, or simultaneously searching for other AK or OE numbers",
            chooseBasket: "Choose basket or create new one",
            basketContent: "Basket content, number — with ability to edit, prices, sums.",
            deliveryMethod: "The method of delivery when selecting AUTO KADA delivery will require the address to be specified for the first time and then the address will be required if you want to ship to another location.",
            orderComments: "Notes for more accurate order execution",
            priceOfferEmail: "If you want to send a price offer to a customer, then enter the customer email here and then click on 'SEND PRICE OFFER' button",
            replyTour: "Reply tour",
            next: "Next",
            finish: "Finish"
        },
        invoices: {
            availableCreditLimit: "Available credit limit",
            totalCreditLimit: "Total credit limit",
            openOrders: "Open orders",
            openInvoices: "Open invoices",
            totalBalance: "Total balance",
            balance: "Balance",
            latePayments: "Late payments",
            invoices: "Invoices",
            invoiceDate: "Invoice date",
            invoiceNumber: "Invoice number",
            totalValue: "Total value",
            remainingValue: "Remaining value",
            dueDate: "Due date",
            status: "Status",
            documentNo: "Document no",
            filter: "Filter",
            discardFilters: "Discard filters",
            from: "From",
            to: "To",
            paid: "Paid",
            unpaid: "Unpaid",
            partiallyPaid: "Partially paid",
            unpaidLate: "Unpaid (late)",
            partiallyPaidLate: "Partially paid (late)",
            clear: "Clear",
            cancel: "Cancel",
            ok: "Okay"
        },
        autocomplete: {
            noOptions: "No options",
            open: "Open",
            close: "Close"
        },
        dataGrid: {
            noResults: "No results"
        },
        itemReturnForm: {
            title: "Return of goods",
            serverError: "Item return form is not available (server error), try again later!",
            submitError: "Server error, try again later!",
            submit: "Submit",
            formFields: {
                customer: "Customer",
                chooseCustomer: "Choose customer",
                searchCustomer: "Search customer",
                partNumber: "Part number",
                enterPartNumber: "Enter valid AK code",
                validAKCode: "AK code is valid",
                docNumber: "Invoice/receipt number",
                enterDocNumber: "Enter document number",
                enterCount: "How many parts will be returned",
                purchaseDate: "Date of purchase",
                legalAddr: "Legal address",
                enterLegalAddr: "Enter legal address",
                itemInstalledDate: "Part installation date",
                itemRemovedDate: "Part removal date",
                itemInstalledOdo: "Odometer indication during the installation of the part (km)",
                enterOdo: "Enter odometer reading (km)",
                itemRemovedOdo: "Odometer indication during the removal of the part (km)",
                vehicleIdentity: "Automobile: chassis no./trailer: manufacturer, bridge no.",
                enterIdentity: "Enter wehicle identification information",
                phone: "Phone",
                enterPhone: "Phone for questions/notifications",
                email: "Additional e-mail",
                returnIfRejected: "Do you want to get the part(s) back if the reclamation is rejected?",
                yes: "Yes",
                no: "No",
                files: "Files",
                enterEmail: "Additional e-mail for questions/notifications",
                description: "Description of the defect",
                provideDescription: "Describe the defect",
                shopReturned: "The shop where the item was/will be returned for review",
                chooseShop: "Choose shop",
                chooseFiles: "Choose files",
                remove: "Remove",
                totalSize: "Total size",
            },
            validationMessages: {
                removalDateComparison: "Removal date must be greater than or equal to installation date",
                installDateComparison: "Installation date cannot be greater than removal date",
                removedOdoComparison: "Removal odometer reading must be greater than or equal to installation odometer reading",
                installedOdoComparison: "Installation odometer reading cannot be greater than removal odometer reading",
                required: "This is required field",
                wholeNumbers: "Please enter whole number",
                validAKCodeNumbers: "AK code must contain only digits",
                invalidAKCode: "Invalid AK code",
                akCodeValidationErr: "AK code validation error. Please try again later!",
                email: "Please enter a valid email address",
                maxDate: "Provided date value is in the future",
                minDate: "Please enter date that is after 2000.01.01",
                invalidDateFormat: "Invalid date format",
                totalFileSize: "Combined file size cannot be over 20MB",
            },
        },
        itemReturnList: {
            title: "Item return applications list",
            serverError: "Returned items list is not available (server error), try again later!",
            tableTitles: {
                itemCardTitle: "Returned item number",
                returnApplication: "Return application",
                formSubmitted: "Form submitted",
                itemNumber: "Item number",
                user: "User",
                shopReturned: "Shop",
                status: "Status",
            },
            statuses: {
                received: "Received",
                inReview: "In review",
                atTheManufacturer: "Sent to manufacturer",
                accepted: "Accepted",
                rejected: "Rejected",
            }
        },
        itemReturnInstructions: {
            mainPoints: {
                first: "RETURN OF THE GOODS MUST BE CARRIED OUT WITHIN 10 DAYS OF THE PURCHASE",
                second: "THE GOODS MUST BE IN UNDAMAGED AND UNTAINTED ORIGINAL PACKAGING",
                third: "PARTS SUBMITTED FOR WARRANTY CONSIDERATION MUST BE CLEANED WITH THE MANUFACTURER'S MARKINGS STILL VISIBLE",
                fourth: "FOR THE CONVENIENCE OF OUR CUSTOMERS, PLEASE FILL IN THE RETURN FORM ELECTRONICALLY",
            },
            faq: {
                description: {
                    question: "What is recommended to be mentioned in the section \"Defect description\"?",
                    answer: "The problem in performance of the part should be described:\n1. How it occurred and when, what actions were taken, etc.;\n2. The problem area must be indicated (marked) on the part;\n3. It is preferable to attach a video confirming the particular defect;\n4. Video capture is obligatory in the case of oil and other fluid leaks, air leaks;\n5. Parts of the electric circuit - must have a computer error printout or measurement results from an electrician (can be in video format);\n6. OE parts: Gas pedals, modulators (OE, Knor-Bremse, WABCO); Electric levelling valves (OE, Knor-Bremse, WABCO); control units (OE, Knor-Bremse, WABCO); control sensors (OE, Knor-Bremse, WABCO); a printout of the error codes must be submitted along with the claimed defective part."
                },
                cases: {
                    question: "In what cases will the item not be returned?",
                    answer: "1. If the original packaging is damaged or tainted or has not been preserved;\n2. No electrical parts are returned if the sealed packaging is damaged;\n3. Repair kits, if their packaging is open;\n4. Specially ordered items are not subject to exchange or return.",
                },
                processLength: {
                    question: "How long does it take to process a warranty claim?",
                    answer: "Probable warranty cases are reviewed and sent to the manufacturer by the AUTO KADA service representative within 1 week.\nThe manufacturer shall be responsible for the remainder of the warranty, including its duration.",
                },
                conditions: {
                    question: "In what condition are old cases accepted?",
                    answer: "The old cases must be clean and free of oil.\nSACHS clutch return general <link1>terms</link1> and <link2>summary</link2>.\nThe return policy for BTS TURBO turbines see <link3>here</link3>.\nReturn rules for EMITEC ADBLUE cases can be found <link4>here</link4>.\nSee the return policy for ALBONAIR ADBLUE cases <link5>here</link5>.\nReturn rules for ECOFIT ADBLUE cases can be found <link6>here</link6>.\nSee the rules for returning brake calipers <link7>here</link7>.WABCO return policy see <link8>here</link8>.\nKNORR-BREMSE return policy see <link9>here</link9>.\nREMA.GERMANY return policy see <link10>here</link10>."
                },
            },
            itemDescription: {
                leakage: "in case of air, gas, or liquid leakage, the location of the leak must be marked on the part;",
                electronics: "for any electrical part, if the defect is not visually apparent and the part requires testing with diagnostic or measuring equipment, attachments must be provided (error code prints, tachograph tapes/cards, current values of measurements taken);",
                rejectedReclamation: "In the case when 'Yes' is selected and the part is sent to the factory for inspection, a fee may be applied for its return.",
            },
        },
        itemReturnItem: {
            part: "Item",
            status: "Status",
            submitDate: "Form submitted",
            lastUpdated: "Last updated",
            docNumber: "Document number",
            count: "Count",
            additionalEmail: "Additional e-mail",
            installDate: "Item installed (date)",
            removeDate: "Item removed (date)",
            installOdo: "Item installed (odometer)",
            removeOdo: "Item removed (odometer)",
            phone: "Phone",
            purchaseDate: "Purchase date",
            registeredOffice: "Legal address",
            shopReturned: "Shop",
            user: "User",
            vehicle: "Vehicle identification",
            userInfo: "User information",
            returnRejected: "Return item if rejected",
            description: "Defect description",
            attachments: "Attachments",
            response: "Response",
            error: "Server error. Try again later!",
        },
    },
};

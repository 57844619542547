import styled from "styled-components";

export const NewVersionPopupContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: white;
    padding: 20px;
    border: 3px solid #f28a22;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    z-index: 10000;

    @media (max-width: 600px) {
        width: 300px;
        font-size: 16px;
    }

    @media (max-width: 350px) {
        width: 250px;
    }
`;

export const ReloadAndLogoutButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 36px;
    font-weight: bold;
    letter-spacing: 0.15px;
    background-color: #f28a22;
    color: white;
    border-radius: 2px;
    margin: 0px 8px 0px 8px;
    padding: 0px 12px 0px 12px;

    &:hover {
        color:rgba(255,255,255,0.8);
    }
    
    @media (max-width: 350px) {
        height: 30px;
        font-size: 12px;
        margin: 0px 5px 0px 5px;
        padding: 0px 5px 0px 5px;
    }
`;

export const PostponeUpdateButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 36px;
    font-weight: bold;
    letter-spacing: 0.15px;
    background-color: #37474f;
    color: white;
    border-radius: 2px;
    margin: 0px 8px 0px 8px;
    padding: 0px 12px 0px 12px;

    &:hover {
        color:rgba(255,255,255,0.8);
    }
    
    @media (max-width: 350px) {
        height: 30px;
        font-size: 12px;
        margin: 0px 5px 0px 5px;
        padding: 0px 5px 0px 5px;
    }
`;
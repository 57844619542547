import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Popper } from "../../../Popper";

import { StockFlyout } from "./StockFlyout";
import { StockAnchor, StockDot, StyledHelp } from "./style";

export const Stock = ({ stock, id, children, marginTop, isRowValue, isAvailibility, style }) => {
    const [popperVisibility, setPopperVisibility] = useState(false);
    const textRef = useRef();
    const { userData } = useSelector((state) => state.global);

    const togglePopper = useCallback(() => {
        setPopperVisibility((curValue) => !curValue);
    }, []);
    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, []);
    const openPopper = useCallback(() => {
        setPopperVisibility(true);
    }, []);

    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const textProps = isPhoneScreen
        ? {
              onClick: togglePopper,
          }
        : {
              onMouseEnter: openPopper,
              onMouseLeave: closePopper,
          };
          
    return (
        <>
            {children && typeof children === "function" ? (
                children(stock.total, { ref: textRef, ...textProps }, stock.show_dot, stock.stocks)
            ) : (
                <StockAnchor
                    aria-describedby={id}
                    {...textProps}
                    ref={textRef}
                    isphone={isPhoneScreen}
                    stockiszero={stock && stock.total}
                    style={{cursor: "pointer", ...style}}
                >
                    {(stock.show_dot && (stock.total > 0 || stock.stocks.length)) ? (
                        <StockDot />
                    ) : stock.total > 0 ? (
                        stock.total
                    ) : !userData?.is_salesperson ? (
                        <StyledHelp />
                    ) : (
                        ""
                    )}
                    {isAvailibility && stock}
                </StockAnchor>
            )}
            {(stock.total > 0 || stock.stocks) &&
            <Popper
                id={id}
                style={{
                    marginTop,
                    zIndex: 5000,
                }}
                visibility={popperVisibility}
                ignoreBackground={true}
                anchorEl={textRef?.current}
                onClickAway={closePopper}
            >  
                <StockFlyout
                    isRowValue={isRowValue}
                    stock={stock.stocks}
                    showDot={stock.total}
                    showDetailedStock={stock.show_detailed_stock}
                    salespersonShop={stock.salesperson_shop}
                    deliveryInfo={stock.show_delivery_info}
                    closePopper={closePopper}
                />
            </Popper>}
        </>
    );
};

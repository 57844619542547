export const sv = {
    translation: {
        "Browse Product Catalog": "BROWSE PRODUCT CATALOG",
        "Show only items on stock": "Show only items on stock",
        "Choose a manufacturer": "Choose a manufacturer",
        "Product Catalog": "Product Catalog",
        email: "E-post",
        submit: "Överlämna",
        newVersionPopupFirst: "AUTO KADA B2B-portalen har uppdaterats!",
        newVersionPopupSecond: "Klicka på 'UPPDATERA' för att tillämpa de senaste ändringarna — därefter måste du logga in på portalen igen!",
        newVersionPopupSecondLoginpage: "Klicka på 'UPPDATERA' för att tillämpa de senaste ändringarna!",
        updateB2BPortal: "Uppdatera",
        postponeB2BUpdate: "Inte nu",
        login: {
            title: "Login",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Användarnamn",
            password: "Lösenord",
            login: "Login",
            forgotPassword: "Glömt lösenord?",
            companyRegistration: "Registrera",
            tnc: "Terms & conditions",
            privacyPolicy: "Integritetspolicy",
            rights: "® 1994 - 2021, Auto Kada. Alla rättigheter förbehållna",
            errorMessageCredentials:
                "Ange ett korrekt användarnamn och lösenord. Observera att båda fälten är skiftlägeskänsliga.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "Genom att klicka nedan för att registrera dig godkänner du vår",
            privacyPolicyAgreement: "Jag godkänner integritetspolicyn.",
        },
        privacyPolicy: {
            title: "SKYDD AV PERSONUPPGIFTER",
            bulletPoints: {
                1: "Parterna kan inom ramen för detta avtal behandla personuppgifter i enlighet med Europaparlamentets och rådets förordning (EU) 2016/679 av den 27 april 2016 om skydd för fysiska personer med avseende på behandling av personuppgifter och om det fria flödet av sådana uppgifter.",
                2: "Parterna bekräftar och åtar sig att de uppgifter (data) de erhåller från varandra (inklusive information, data från denna webbplats, B2B-systemet) får inte överföras till tredje part utan den andra partens medgivande.",
                3: "Parterna ska vidta lämpliga tekniska och organisatoriska åtgärder för att säkerställa att som standard endast sådana personuppgifter som krävs för parternas verksamhet inom ramen för detta avtal och för varje specifikt behandlingsändamål behandlas. Denna skyldighet avser omfattningen av insamlade och överförda personuppgifter, deras behandlingsgrad, lagringsperiod och tillgänglighet.",
                4: "Parterna ska säkerställa, regelbundet granska och förbättra skyddsåtgärder för att skydda personuppgifter från otillåten tillgång, oavsiktlig förlust, avslöjande eller förstörelse. Den ena parten är inte ansvarig för obehörig tillgång till personuppgifter och/eller förlust av personuppgifter som inte orsakats av den nämnda parten utan beror på, t.ex. den andra partens eller en tredje parts fel och/eller försumlighet.",
                5: "Vid brott mot skydd av personuppgifter ska den ena parten omedelbart underrätta den andra parten och utan oskäligt dröjsmål underrätta den behöriga tillsynsmyndigheten som ansvarar för brott mot skydd av personuppgifter (Dataskyddsmyndigheten - www.dvi.gov.lv) senast 72 timmar efter det att brottet blev känt för parten, utom i fall där det är osannolikt att ett brott mot personuppgifter skulle kunna utgöra en risk för fysiska personers rättigheter och friheter.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Glömt lösenord? Ange din mailadress nedan. Du kommer sedan att få instruktioner för att ange nytt lösenord.",
                errorMsg: "Ange en giltig mejladress.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "Vi har mailat dig instruktioner för att skapa lösenord till den e-mail adress du angivit. Då kommer att få detta mail inom kort.",
                text2:
                    "Om du ej har fått något mail, kontrollera att du angivit rätt mailadress. Kontrollera även din skräp mail låda.",
            },
            resetPasswordStep: {
                text:
                    "Skriv ditt nya lösenord två gånger för att verifiera detta.",
                newPassword: "Nytt lösenord",
                confirmPassword: "Bekräfta lösenord",
                submit: "Ändra lösenord",
                errorMsg: "Lösenord matchar inte.",
            },
            invalidResetLinkStep: {
                text:
                    "Länken för återställande av lösenord var ogiltig, då den redan använts. Vänlig begär ny återställning.",
            },
            resetPasswordSuccessStep: {
                text: "Ditt lösenord är registrerat. Du kan logga in nu.",
                login: "Log in",
            },
        },
        breadcrumbs: {
            backTitle: "Tillbaka till",
        },
        register: {
            title: "REGISTRERING",
            company: {
                subtitle: "FÖRETAG",
                name: "Företagsnamn",
                registrationNumber: "Organisationsnummer",
                vatRegistrationNr: "Momsregistreringsnummer",
                address: "Adress",
                city: "Stad",
                postalCode: "Postnummer",
                country: "Land",
                chooseCountry: "Välj ditt land",
                email: "E-post",
                phoneNumber: "Telefonnummer",
            },
            user: {
                subtitle: "ANVÄNDARE",
                usernameInputExplanation:
                    "Krävs 150 tecken eller färre. Bokstäver, siffror och @. endast + - _.",
                password: "Lösenord",
                passwordConfirmation: "lösenordsbekräftelse",
                firstName: "Förnamn",
                lastName: "Efternamn",
                email: "E-post",
            },
            register: "REGISTRERA",
            validationMessages: {
                name: "Namnfältet måste innehålla minst 2 och högst 30 tecken. Den bör inte innehålla specialtecken.",
                surname: "Efternamnfältet måste innehålla minst 2 och högst 30 tecken. Den bör inte innehålla specialtecken.",
                required: "Detta fält krävs",
                userName: "Ange ett giltigt användarnamn",
                phoneNumber: "Telefonnumret ska bestå av 11 siffror med ett ledande 0.",
                password: "Lösenordet måste vara 6-20 tecken långt och innehålla minst en bokstav och en siffra",
                confirmPassword: "Lösenorden matchar inte",
                tos: "Läs och bekräfta användaravtalet.",
                email: "Ange en giltig e-postadress.",
            },
            registrationSuccess: "Registrering är komplett. Ansvarig kommer att kontakta dig inom kort.",
        },
        headerUserMenu: {
            impersonateCustomer: "Efterlikna kund",
            stopImpersonation: "Avsluta simulering",
            switchLanguage: "Ändra språk",
            switchCountry: "Växla land",
            orderHistory: "Orderhistorik",
            priceOffersHistory: "Historik över prisoffer",
            itemReturns: "Artiklar returneras / garanti",
            logout: "Logga ut",
        },
        header: {
            search: "",
            searchByCode: "OE/OEM/leverantörsnummer",
            searchByVehicle: "VIN numurs",
            searchByPlate: "Nummerplåt",
            byCode: "På kod",
            byVehicle: "På fordon",
            byManufacturer: "På tillverkare",
            byPlate: "På nummerplåt",
            searchHistory: "SÖKHISTORIK",
            setAsDefaultCart: "Ställ in som standardvagn",
            removeDefaultCart: "Ta bort standardvagnen",
            emptyCart: "Töm varukorg",
            deleteCart: "Radera varukorgen",
            confirm: "Bekräfta",
            cancel: "Annullera",
            emptyCartMessage: "Tömning av vagnen tar bort alla dess föremål! Vill du fortsätta?",
            deleteCartMessage: "Varukorgen kommer att raderas och alla dess föremål tas bort! Vill du fortsätta?",
            carByParameters: {
                title: "Identifiering av bil genom parametrar",
                listVehicles: "Lista fordonen",
                modifications: "Visa fler mods",
            },
            byVinFrame: {
                title: "På VIN/Frame",
                tip: "Ange VIN eller ramnummer",
                errorVehicleSearch: "Det gick inte att hitta fordonet med de angivna parametrarna",
            },
            tags: {
                addTag: "Välj en befintlig tagg eller skapa en ny",
                inputTag: "Ange en tagg",
                createTag: "Skapa och lägg till en tagg",
            },
            comments: {
                addComment: "Vänligen lägg till en kommentar",
                inputComment: "Skriv en kommentar",
                createComment: "Skapa och lägg till en kommentar"
            }
        },
        sideMenu: {
            browseProductCatalog: "Produktkatalog",
            newProducts: "Nya produkter",
            onlyItemsOnStock: "Visa endast i lager",
            chooseAManufacturer: "Välj Tillverkare",
        },
        notification: {
            actionName: {
                added: "har lagts till i varukorg",
                removed: "har tagits bort från varukorgen",
                addedFav: "har lagts till i favoriter",
                tooManyFav: "Favoritgränsen (100) har nåtts! Vänligen ta bort några objekt innan du lägger till nya!",
                removedFav: "har tagits bort från favoriter",
                removedAvailNotification: "borttagen från listan med tillgänglighetsaviseringar",
                addedAvailNotification: "läggs till i listan över tillgänglighetsaviseringar",
                cartEmptied: "Varukorg har tömts:",
                cartDeleted: "Varukorg har raderats:",
                cartDefaulted: "Ställ in som varukorg:",
                cartUndefaulted: "Borttagen varukorg:",
                addedTag: "lagt till i taggen",
                tagDeleted: "Taggen har tagits bort:",
                theTag: "Taggen",
                removedFrom: "har tagits bort från:",
                commentSet: "Kommentaren är satt",
                commentRemoved: "Kommentaren har tagits bort",
                commentExists: "Kommentar finns redan"
            },
            itemHasBeen: "Artikel",
        },
        productsList: {
            fetching: "Laddar in fler produkter...",
            vehicle: "Fordon",
            productCatalog: "Produktkatalog",
            withDiscount: "Priser med rabatt",
            withVAT: "Priser med moms",
            noInfoError: "Det finns ingen information i systemet",
            availability: {
                green: "- Centrallager",
                orange: "- Andra AK-lager",
                yellow: "- Andra lager",
                grey: "- På begäran",
                transit: "- På väg",
            },
            order: "Beställning",
            sendPriceOffer: "Skicka prisoffert",
            successfulOrder: "Order har accepterats",
            successfulOffer: "Prisofferten har skickats",
            orderHistory: {
                title: "Orderhistorik",
                number: "Antal",
                created: "Skapad",
                state: "Uppge",
                user: "Användare",
                customer: "Kund",
                total: "Summa",
                sourceLocation: "Beställs från",
                vendorCode: "Säljkod",
                description: "Beskrivning",
                unitPrice: "Styckpris",
                count: "Antal",
                return: "Returnera",
                delivery: "Leverans",
                items: "Artikel",
                offerReceiver: "Erhållare av erbjudandet",
            },
            tableHeader: {
                image: "Bild",
                product: "Produkt",
                AKCode: "AK kod",
                oem: "OEM kod",
                vendor: "Tillverkare",
                stock: "Lager",
                availability: "Tillgänglighet",
                price: "Pris",
                quantity: "Lägg till i inköpsvagn",
                comment: "Kommentar",
                tags: "Produkttaggar",
            },
            tableVehiclesHeader: {
                description: "beskrivning",
                model: "ÅRSMODELL",
                build: "bygga",
                tonnage: "TONNAGE",
                axle: "AXELKONFIG",
            },
            tablePurchaseHistoryHeader: {
                date: "Inköpsdatum",
                count: "Antal",
                unitPrice: "Enhetspris",
                tags: "Taggar",
                user: "Användare",
            },            
            stockFlyout: {
                title: "LAGERSTILLGÄNGLIGHET",
                deliveryInfo: "Möjlig\nEXPRESS 24h + SEK",
                pleaseAsk: "VÄNLIGEN FRÅGA:",
                deliveryDate: "leveransdatum",
            },
            favouriteFlyout: {
                add: "Lägg till i favoriter",
                remove: "Ta bort från favoriter",
            },
            notificationFlyout: {
                add: "Lägg till i listan över tillgänglighetsmeddelanden",
                remove: "Ta bort från listan med tillgänglighetsaviseringar",
            },
            commentFlyout: {
                add: "Lägg till en kommentar",
            },
            tagFlyout: {
                add: "Lägg till taggar",
            },
            publicFlyout: {
                text: "Tillgänglighet, beställning, OE-koder, Sök med VIN-nr. endast tillgängligt för registrerade användare.",
                link: "Registrera dig här",
            },
            cartButton: {
                title: "LÄGG TILL I KUNDVAGN",
                flyout: {
                    title: "Välj befintlig vagn eller skapa ny",
                    cartName: "Kundvagnens namn",
                    btnCaption: "SKAPA OCH LÄGG TILL PUNKT",
                    inputPlaceholder: "Ange kundvagnens namn",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Det är inte nödvändigt att beställa dessa varor eftersom de har automatisk leverans",
                quantity: "Räkna: ",
                totalWithoutVAT: "Summa exklusive moms:",
                VAT: "Moms",
                total: "Summa"
            },
            sourceLocation: "Beställs från",
            deliveryType: {
                title: "Leveranssätt",
                deliveryAddress: {
                    title: "Leveransdress",
                    name: "Namn",
                    address: "Adress",
                    city: "Stad",
                    postalCode: "Postnummer",
                    email: "E-post",
                    phone: "Telefon",
                    new: "Ny",
                },
                pickup: "Avhämtningsadress",
            },
            hasRelatedCodes: "Denna produkt har relaterade koder",
            commentsTitle: "Kommentarer",
            priceOfferTitle: "Mottagare av prisoffert",
            user: "Användare",
            date: "Datum",
            detailsModal: {
                tabs: {
                    relatedCodes: "RELATERADE KODER",
                    alternativeProducts: "ALTERNATIVA PRODUKTER",
                    specification: "SPECIFIKATION",
                    oemCodes: "OEM-KODER",
                    linkedVehicles: "LÄNKADE FORDON",
                    purchaseHistory: "KÖPHISTORIK",
                },
            },
        },
        appTour: {
            searchByCode: "B2B söker efter varor med både AUTO KADAs lagerkoder och originaltillverkarens nummer",
            logoButton: "För att återvända till startsidan, klicka på AUTO KADA-loggan eller på länken “Gå tillbaka”",
            grossPrices: "Visa bruttobelopp eller visa kundpriser",
            vatPrices: "Visa priser med eller utan moms",
            productCatalog: "Produktkatalogen har snabbsökning efter varugrupper",
            // searchByVin: "Sök med VIN-kod (chassinummer)",
            languageMenu: "Språk, orderhistorik, fakturor och artikelretur/garantimeny",
            salespersonLanguageMenu: "Meny: språk, beställningshistorik, historik för priserbjudanden, fakturor, artikelretur/garanti",
            returnForm: "Om du behöver returnera en produkt, vänligen fyll i formuläret för 'Retur av varor'!",
            returnApplicationsList: "Här kan du få tillgång till alla dina inskickade produktreturansökningar och kontrollera deras status och svar.",
            shoppingCart: "Varukorg",
            tagItem: "Lägg till en tagg (till exempel för att markera att produkten är avsedd för en specifik bil).\nDe taggar som läggs till på produkten kommer att vara synliga i köphistoriken för objektet. Du kan ta bort en specifik tagg för en produkt här eller genom att öppna produkten. Alla skapade taggar och de produkter som är associerade med dem kan ses genom att klicka på bilikonen högst upp på sidan, där du också kan ta bort skapade taggar (att ta bort en tagg kommer att ta bort alla produkter som är associerade med den).",
            commentItem: "Lägg till en kommentar (anteckning) till produkten. Kommentaren kommer bara att vara synlig för dig. Du kan visa eller ta bort kommentaren genom att öppna produkten till vilken den har lagts till.",
            itemsInStock: "Visa endast varor på lager",
            selectedManufacturer: "Vald tillverkare",
            notificationsAndFavorites: "Lägg till produkten till dina favoriter genom att klicka på kugghjulsikonen.\nLägg till produkten till din notifikationslista genom att klicka på klockikonen (synlig bredvid kugghjulsikonen om tillämpligt) för att få ett meddelande när den finns i lager igen.\nDina listor över tillagda produkter är tillgängliga längst upp på sidan bredvid kundvagnsikonen. Om någon av produkterna som läggs till i aviseringslistan blir tillgängliga kommer du att se ett antal tillgängliga produkter som visas i en grön cirkel bredvid ikonen för meddelandelistan.",
            favorites: "Lägg till produkten till dina favoriter genom att klicka på kugghjulsikonen.\nDin lista över favoritprodukter är tillgänglig högst upp på sidan bredvid kundvagnsikonen.",
            // manufacturersMenu: "Tillverkare",
            searchParameters: "Valda sökparametrar, sökning sker endast utefter dessa parametrar, även om man söker med AK-kod eller OE-nummer",
            particularProduct: "Om du klickar på en viss produkt öppnas ett ytterligare fönster som visar olika relaterade produkter. Samtidigt visas teknisk information, bilder, originalnummer och olika modeller för vilka produkten är avsedd, samt produktens köphistorik.",
            searchDone: "Sökningen sker efter att du skrivit in en hel VIN-kod (chassinummer)",
            openDetails: "När du klickar på en sökt reservdel för att öppna den, visas de tillverkare, mängder och priser som finns tillgängliga på AUTO KADAs lager.",
            pictureNumbers: "Du kan också söka genom att trycka på siffrorna bredvid den detalj du är intresserad av.",
            addToCart: "Lägg till en vara i varukorgen direkt från VIN-katalogen. PS För att underlätta arbetet kan du öppna flera fönster eller flikar i en webbläsare för att söka efter flera VIN-koder eller samtidigt söka med andra Auto Kada-koder eller OE-nummer.",
            chooseBasket: "Välj korg eller skapa ny",
            basketContent: "Varukorgens innehåll, antal, med möjlighet att redigera priser, summor",
            deliveryMethod: "Leveranssätt, val emellan AUTO KADAs leverans eller post - första gången måste du ange adress och efter det behöver du bara ange adress om den ändrats.",
            orderComments: "Återkoppling för en bättre utförd beställning",
            priceOfferEmail: "Om du vill skicka ett prisförslag till en kund, ange kundens e-post här och klicka sedan på 'SKICKA PRISOFFERT' - knappen",
            replyTour: "Upprepa instruktionen",
            next: "Nästa",
            finish: "Slutföra"
        },
        invoices: {
            availableCreditLimit: "Tillgänglig kreditgräns",
            totalCreditLimit: "Total kreditgräns",
            openOrders: "Öppna beställningar",
            openInvoices: "Öppna fakturor",
            totalBalance: "Total saldo",
            balance: "Balans",
            latePayments: "Sena betalningar",
            invoices: "Fakturor",
            invoiceDate: "Fakturadatum",
            invoiceNumber: "Fakturanummer",
            totalValue: "Totala värdet",
            remainingValue: "Återstående värde",
            dueDate: "Förfallodatum",
            status: "Status",
            documentNo: "Dokument nr",
            filter: "Filtrera",
            discardFilters: "Kassera filter",
            from: "Från",
            to: "Upp till",
            paid: "Betalt",
            unpaid: "Obetald",
            partiallyPaid: "Delvis betald",
            unpaidLate: "Obetald (sent)",
            partiallyPaidLate: "Delvis betald (sent)",
            clear: "Klar",
            cancel: "Annullera",
            ok: "Okej"
        },
        autocomplete: {
            noOptions: "Inga val",
            open: "Öppet",
            close: "Stänga"
        },
        dataGrid: {
            noResults: "Inga resultat"
        },
        itemReturnForm: {
            title: "Returnera varor",
            serverError: "Formuläret för returnering av varor är inte tillgängligt (serverfel), försök igen senare!",
            submitError: "Serverfel, försök igen senare!",
            submit: "Skicka in",
            formFields: {
                customer: "Kund",
                chooseCustomer: "Välj kund",
                searchCustomer: "Sök kund",
                partNumber: "Artikelnummer",
                enterPartNumber: "Ange giltig AK-kod",
                validAKCode: "AK-koden är giltig",
                docNumber: "Faktura/kvittonummer",
                enterDocNumber: "Ange dokumentnummer",
                enterCount: "Hur många delar kommer att returneras",
                purchaseDate: "Inköpsdatum",
                legalAddr: "Juridisk adress",
                enterLegalAddr: "Ange juridisk adress",
                itemInstalledDate: "Installationsdatum för delen",
                itemRemovedDate: "Avlägsnandets datum för delen",
                itemInstalledOdo: "Odometer-indikation under installation av delen (km)",
                enterOdo: "Ange odometeravläsning (km)",
                itemRemovedOdo: "Odometer-indikation under avlägsnande av delen (km)",
                vehicleIdentity: "Bil: chassinummer/trailer: tillverkare, bro-nummer",
                enterIdentity: "Ange fordonsidentifieringsinformation",
                phone: "Telefon",
                enterPhone: "Telefon för frågor/meddelanden",
                email: "Extra e-post",
                returnIfRejected: "Vill du få tillbaka delen/delarna om reklamationen avslås?",
                yes: "Ja",
                no: "Nej",
                files: "Filer",
                enterEmail: "Extra e-post för frågor/meddelanden",
                description: "Beskrivning av felet",
                provideDescription: "Beskriv felet",
                shopReturned: "Butiken där varan har/vara kommer att returneras för granskning",
                chooseShop: "Välj butik",
                chooseFiles: "Välj filer",
                remove: "Ta bort",
                totalSize: "Total storlek",
            },
            validationMessages: {
                removalDateComparison: "Avlägsnandets datum måste vara större än eller lika med installationsdatumet",
                installDateComparison: "Installationsdatumet kan inte vara större än avlägsnandets datum",
                removedOdoComparison: "Odometeravläsningen vid avlägsnande måste vara större än eller lika med odometeravläsningen vid installationen",
                installedOdoComparison: "Odometeravläsningen vid installationen kan inte vara större än odometeravläsningen vid avlägsnandet",
                required: "Detta är ett obligatoriskt fält",
                wholeNumbers: "Ange ett heltal",
                validAKCodeNumbers: "AK-koden måste endast innehålla siffror",
                invalidAKCode: "Ogiltig AK-kod",
                akCodeValidationErr: "Fel vid validering av AK-kod. Vänligen försök igen senare!",
                email: "Ange en giltig e-postadress",
                maxDate: "Det angivna datumet är i framtiden",
                minDate: "Ange ett datum efter 2000-01-01",
                invalidDateFormat: "Ogiltigt datumformat",
                totalFileSize: "Den sammanlagda filstorleken får inte vara större än 20 MB"
            }
        },
        itemReturnList: {
            title: "Lista över ansökningar om returnering av varor",
            serverError: "Listan över returnerade varor är inte tillgänglig (serverfel), försök igen senare!",
            tableTitles: {
                itemCardTitle: "Återlämnat artikelnummer",
                returnApplication: "Returnera ansökan",
                formSubmitted: "Formulär inskickat",
                itemNumber: "Artikelnummer",
                user: "Användare",
                shopReturned: "Butik",
                status: "Status",
            },
            statuses: {
                received: "Mottagen",
                inReview: "Under granskning",
                atTheManufacturer: "Skickad till tillverkaren",
                accepted: "Accepterad",
                rejected: "Avvisad",
            }
        },        
        itemReturnInstructions: {
            mainPoints: {
                first: "RETURNERING AV VAROR MÅSTE UTFÖRAS INOM 10 DAGAR FRÅN KÖPET",
                second: "VARORNA MÅSTE VARA I OÖVERSKÄDLIGT OCH OOTAT ORIGINALFÖRPACKNING",
                third: "DELAR SOM SKICKAS IN FÖR GARANTIBEDÖMNING MÅSTE VARA RENGJORDA MED TILLVERKARENS MÄRKNINGAR FORTFARANDE SYNLIGA",
                fourth: "FÖR VÅRA KUNDERS BEKVÄMLIGHET, VÄNLIGEN FYLL I RETURNERINGSFORMULÄRET ELEKTRONISKT",
            },            
            faq: {
                description: {
                    question: "Vad rekommenderas att nämnas i avsnittet \"Felbeskrivning\"?",
                    answer: "Problemet med prestanda för delen bör beskrivas:\n1. Hur det uppstod och när, vilka åtgärder som vidtogs, osv.;\n2. Problemområdet måste markeras på delen;\n3. Det är att föredra att bifoga en video som bekräftar det specifika felet;\n4. Videoinspelning är obligatorisk vid läckage av olja och andra vätskor, luftläckage;\n5. Delar av elektrisk krets - måste ha en utskrift av datorfel eller mätningsresultat från en elektriker (kan vara i videoformat);\n6. OE-detaljer: Gaspedaler, modulatorer (OE, Knor-Bremse, WABCO); Elektriska nivåventiler (OE, Knor-Bremse, WABCO); styrenheter (OE, Knor-Bremse, WABCO); kontrollsensorer (OE, Knor-Bremse, WABCO); en utskrift av felkoderna måste lämnas in tillsammans med den påstådda defekta delen."
                },
                cases: {
                    question: "I vilka fall kommer varan inte att returneras?",
                    answer: "1. Om den ursprungliga förpackningen är skadad eller taintad eller inte har bevarats;\n2. Inga elektriska delar returneras om den förseglade förpackningen är skadad;\n3. Reparationskit, om deras förpackning är öppen;\n4. Särskilt beställda varor omfattas inte av utbyte eller retur."
                },
                processLength: {
                    question: "Hur lång tid tar det att behandla en garantianspråk?",
                    answer: "Sannolika garantifall granskas och skickas till tillverkaren av AUTO KADA-servicerepresentanten inom 1 vecka.\nTillverkaren ansvarar för resten av garantin, inklusive dess varaktighet."
                },
                conditions: {
                    question: "I vilket skick accepteras gamla fall?",
                    answer: "De gamla fallen måste vara rena och fria från olja.\nSACHS koppling återvändingsvillkor <link1>här</link1> och <link2>sammanfattning</link2>.\nReturvillkoren för BTS TURBO-turbiner finns <link3>här</link3>.\nReturvillkoren för EMITEC ADBLUE-fall finns <link4>här</link4>.\nSe returvillkoren för ALBONAIR ADBLUE-fall <link5>här</link5>.\nReturvillkoren för ECOFIT ADBLUE-fall finns <link6>här</link6>.\nSe reglerna för att returnera bromsok <link7>här</link7>.WABCO returpolicy se <link8>här</link8>.\nKNORR-BREMSE returpolicy se <link9>här</link9>.\nREMA.GERMANY returpolicy se <link10>här</link10>."
                }
            },
            itemDescription: {
                leakage: "vid luft-, gas- eller vätskeläckage måste läckans plats markeras på delen;",
                electronics: "för alla elektriska delar, om felet inte är visuellt uppenbart och delen kräver testning med diagnostik- eller mätutrustning, måste bilagor tillhandahållas (felkodutskrifter, färdskrivartape/ kort, aktuella värden för utförda mätningar);",
                rejectedReclamation: "Om 'Ja' är valt och delen skickas till fabriken för inspektion, kan en avgift tas ut för retur.",
            },
        },
        itemReturnItem: {
            part: "Artikel",
            status: "Status",
            submitDate: "Formulär inskickat",
            lastUpdated: "Senast uppdaterad",
            docNumber: "Dokumentnummer",
            count: "Antal",
            additionalEmail: "Extra e-post",
            installDate: "Artikel installerad (datum)",
            removeDate: "Artikel borttagen (datum)",
            installOdo: "Artikel installerad (miltal)",
            removeOdo: "Artikel borttagen (miltal)",
            phone: "Telefon",
            purchaseDate: "Inköpsdatum",
            registeredOffice: "Juridisk adress",
            shopReturned: "Butik",
            user: "Användare",
            vehicle: "Fordonsidentifiering",
            userInfo: "Användarinformation",
            returnRejected: "Returnera varan om den avslås",
            description: "Beskrivning av fel",
            attachments: "Bilagor",
            response: "Svar",
            error: "Serverfel. Försök igen senare!"
        },
    },
};
